import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../../Networking/networkutils";
import { withSnackbar } from 'notistack';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import { LoopCircleLoading } from 'react-loadingg';
import { Button, DialogActions, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { blue } from '@material-ui/core/colors';
import AddTender from './AddTender'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Summary from './Summary';
import { errorMessage } from '../../Helper/ErrorMessage';
// import ReactGA from 'react-ga';
// import Moment from 'react-moment';
import moment from "moment";
import { getCookie } from '../../Helper/CookieHelper';
import { getDarkMode, getPrimaryColor } from '../../Helper/CommonThings';
import AllRateTabs from '../Forms/AllRateTabs';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

// var dialogmsg = "";
// var submitdata = null;
class TenderProjects extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            rates: this.props.rates,
            data2: this.props.roles,
            open: false,
            isLoading: true,
            isLoading1: true,
            allData: 'null',
            openEdit: false,
            openCalc: false,
            key: 0,
            subproj: [],
            actions: [],
            openSelectUser: false,
            activeUsers: [],
            selectedUser: null,
            dialogLoading: false,
            showAlert: false,
            selectedDS: null,
            buttonDisabled: false
        }
    }


    componentDidMount() {
        // console.log("twice")
        this.fetchOps()

    }

    putOps(newData) {
        Service.put('/bb/api/tender/project/' + newData.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                id: newData.id,
                name: newData.name,
                audit_status: newData.audit_status,
            }
        })
            .then(res => {
                this.props.enqueueSnackbar('Updated Successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    fetchOps() {


        if (!token) {
            window.location.href = '/'
        }
        Service.get('/bb/api/tender/project/estimates_ds/?pid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                this.setState({
                    data: res.data,
                    isLoading: false,
                    isLoading1: false
                });

                this.setAccessIcons();
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });


    }

    setAccessIcons() {

        var actions = [];
        if ((access && access.access && access.access.l6a && access.access.l6a.l6a_l) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) {
            actions.push(rowData => ({
                icon: 'functions',
                tooltip: 'Summary',
                onClick: (event, rowData) => { this.setState({ allData: rowData, openCalc: true, }) },
                disabled: (rowData.audit_status === 0 || rowData.audit_status === 4)
            }))
        }
        if ((access && access.access && access.access.l6a && access.access.l6a.l6a_f) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) {
            actions.push(rowData => ({
                icon: 'book',
                tooltip: 'Estimate',
                onClick: (event, rowData) => { this.setState({ allData: rowData, key: 2, open: true, }) },
                disabled: !(rowData.audit_status === 0 || rowData.audit_status === 4 || rowData.audit_status === 10 || (rowData.audit_status === 11 && (access && access.access && access.access.l6a && access.access.l6a.l6a_n)))
            }))
        }
        if ((access && access.access && access.access.l6a && access.access.l6a.l6a_i) || (access && access.access && access.access.l6a && access.access.l6a.l6a_g)) {
            actions.push(rowData => ({
                icon: 'assessment',
                tooltip: 'Mini Report',
                onClick: (event, rowData) => { window.open('/ds/mini/report/' + rowData.id + "/" + this.props.value.id) },

            }))
        }
        if ((access && access.access && access.access.l6a && access.access.l6a.l6a_g) || (this.props.value.tenderbid_data.pdm === user.id || this.props.value.tenderbid_data.region_hoe.id === user.id)) {
            actions.push(rowData => ({
                icon: () => <PublishedWithChangesIcon />,
                tooltip: 'Export as PDF - Changes',
                onClick: (event, rowData) => { this.openSelectUser(rowData) },
                hidden: rowData.type === 0,
                // disabled: this.props.value.tenderbid_data.pdm !== user.id
            }))
        }

        this.setState({ actions })
    }

    // giveaccess() {

    //     var json = {
    //         email: submitdata.email,
    //         is_staff: true,
    //         is_lead: true,
    //         is_admin: !submitdata.is_admin,
    //     }

    //     Service.put("/bb/api/tender/project/" + submitdata.id + "/", {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //         data: json,
    //     })
    //         .then(res => {

    //             this.setState({ open: false, });
    //             // console.log(res.data)
    //             this.props.enqueueSnackbar('Permission changed for ' + submitdata.email, {
    //                 variant: 'success'
    //             });
    //             this.fetchOps();
    //         })
    //         .catch(error => {
    //             // console.log(error)
    //             this.props.enqueueSnackbar('Something went wrong!', {
    //                 variant: 'error'
    //             });
    //         });
    // }
    costCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_pid === rowData.id) {
                cost = cost + this.costCalc2(element)
            }
        }
        return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(cost);
    }

    costCalc2(data1) {
        // console.log(data1)
        for (let j = 0; j < this.state.rates.length; j++) {
            const element1 = this.state.rates[j];
            if (element1.t_pid === data1.t_pid) {
                if (data1.country === 'in' || data1.country === 'sl') {
                    for (let i = 0; i < this.state.rates[j].t_rates.length; i++) {
                        const element = this.state.rates[j].t_rates[i];
                        if (data1.grade.toUpperCase() === element.grade) {
                            // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                            return data1.hours * element.costin;
                        }
                    }
                } else {
                    for (let i = 0; i < this.state.rates[j].t_rates.length; i++) {
                        const element = this.state.rates[j].t_rates[i];
                        if (data1.grade.toUpperCase() === element.grade) {
                            return data1.hours * element.costuk;
                        }
                    }
                }
                break;
            }
        }

        return 0;
    }

    hourCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_pid === rowData.id) {
                cost = cost + parseFloat(element.hours);
            }
        }
        return cost.toFixed(2);
    }

    dateParse(date) {
        var formatDate = moment(date).format('DD/MM/YYYY');
        // console.log(formatDate)
        if (formatDate === 'Invalid date') {
            return 'NA';
        } else {
            return formatDate;
        }
    }

    openSelectUser(data) {
        this.setState({
            openSelectUser: true,
            // dialogLoading: true, 
            selectedDS: data
        })
        // Service.get('/bb/api/users/active_users/', {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        // })
        //     .then(res => {
        //         this.setState({
        //             activeUsers: res.data,
        //             dialogLoading: false,
        //         });
        //     })
        //     .catch(error => {
        //         console.log(error)
        //         errorMessage(this.props, error)
        //     });
    }

    sendPDFMail(type) {
        // if (!this.state.selectedUser) {
        //     this.props.enqueueSnackbar('Please Select User!', {
        //         variant: 'warning'
        //     });
        // } else {
        this.setState({ buttonDisabled: true })
        var url = ''
        if (type === 'selected_user') {
            url = `/bb/api/report/view/pdf_print_option/?ds=${this.state.selectedDS.id}&send_to=${this.state.selectedUser.id}`
        } else if (type === 'me') {
            url = `/bb/api/report/view/pdf_print_option/?ds=${this.state.selectedDS.id}`
        }

        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    // selectedDS: null,
                    // selectedUser: null,
                    openSelectUser: false,
                    buttonDisabled: false
                });
                // const url = window.URL.createObjectURL(new Blob([res.data]));
                // const link = document.createElement("a");
                // link.href = url;
                // link.setAttribute("download", this.state.selectedDS.name + ".pdf");
                // document.body.appendChild(link);
                // link.click();
                // console.log(res.data)
                // this.iframeGenerator(res.data, this.state.selectedDS.name)

                var myWindow = window.open("", "MsgWindow", "width=" + (window.innerWidth - 50) + ",height=" + (window.innerHeight - 75));
                myWindow.document.write(res.data)
                setTimeout(() => {
                    myWindow.print()
                }, 250)
                myWindow.onafterprint = function(e){ 
                    myWindow.close()   
                }
                myWindow.oncancelprint = function(e){ 
                    myWindow.close()   
                }
                
            })
            .catch(error => {
                this.setState({ buttonDisabled: false })
                console.log(error)
                errorMessage(this.props, error)
            });
        // }

    }

    // iframeGenerator(htmlString, pdfname) {
    //     let iframe = document.createElement("iframe");
    //     let iframeWidth = 800
    //     iframe.setAttribute("style", "width: " + iframeWidth + "px; height: " + iframe * 1.425 + "px")
    //     iframe.style.visibility = "hidden";
    //     document.body.appendChild(iframe);
    //     let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
    //     iframedoc.body.innerHTML = htmlString;

    //     setTimeout(() => {
    //         this.htmlStringToPdf(iframe, iframedoc, pdfname)
    //         this.setState({ selectedDS: null })
    //     }, 100)
    // }

    // htmlStringToPdf = async (iframe, iframedoc, pdfname) => {

    //     let canvas = await html2canvas(iframedoc.body, { scale: 2 });

    //     // Convert the iframe into a PNG image using canvas.
    //     let imgData = canvas.toDataURL("image/png");

    //     // Create a PDF document and add the image as a page.
    //     const doc = new JsPDF({
    //         format: "a4",
    //         unit: "mm",
    //     });
    //     doc.addImage(imgData, "PNG", 5, 5, 200, 285);
    //     doc.addImage("bb.png", "PNG", 7, 7, 32, 15);
    //     doc.addImage("logo512.png", "PNG", 190, 290, 4, 4);

    //     // Get the file as blob output.
    //     let blob = doc.output("blob");

    //     // Remove the iframe from the document when the file is generated.
    //     document.body.removeChild(iframe);

    //     const url = window.URL.createObjectURL(blob);
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", pdfname + ".pdf");
    //     document.body.appendChild(link);
    //     link.click();
    // };

    onUserChange = (event, values) => {
        this.setState({ selectedUser: values }, () => {
            if (this.state.selectedUser !== null && this.state.selectedUser.id === user.id) {
                this.setState({ showAlert: true })
            }
        });
    }

    render() {
        const { classes } = this.props;
        const handleClose = () => {
            this.setState({ open: false, isLoading1: true });
            this.fetchOps();
            // this.props.onReload();
        };

        const handleClose1 = () => {
            this.setState({ openEdit: false, });
        };

        const handleClose2 = () => {
            this.setState({ openCalc: false, });
        };

        const handleSelectUserClose = () => {
            this.setState({ openSelectUser: false, selectedUser: null, selectedDS: null });
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <MaterialTable
                            title="Deliverables Schedule"
                            columns={[
                                {
                                    title: 'id', field: 'id',

                                },
                                {
                                    title: 'Budget / Change', field: 'type', editable: 'never', headerStyle: { minWidth: 30 }, cellStyle: { minWidth: 30 },
                                    lookup: { 0: 'Budget', 1: 'Change', },
                                },
                                { title: 'Deliverable Schedule', field: 'name', headerStyle: { minWidth: 300 }, cellStyle: { minWidth: 300 }, },
                                {
                                    title: 'Start Date', field: 'from_date', editable: 'never',
                                    render: rowData => this.dateParse(rowData.from_date)
                                },
                                {
                                    title: 'Finish date', field: 'to_date', editable: 'never',
                                    render: rowData => this.dateParse(rowData.to_date)
                                },
                                {
                                    title: 'Hours', field: 'budget_hours', editable: 'never',
                                    render: rowData => rowData.budget_hours
                                },
                                {
                                    title: 'Amount', field: 'budget_cost', editable: 'never',
                                    render: rowData => new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.budget_cost)
                                },
                                {
                                    title: 'Stage', field: 'audit_status', editable: (access && access.access && access.access.l6a && access.access.l6a.l6a_m) ? ('onUpdate') : ('never'),
                                    lookup: {
                                        0: 'Stage 2 - Tender Development', 1: 'Stage 2 - Awaiting Approval', 2: 'Stage 2 - Submission Approved', 3: 'Stage 3 - Tender Submitted', 4: 'Stage 3a - Tender Repricing', 5: 'Stage 3a - Awaiting Approval',
                                        6: 'Stage 3a - Approved', 7: 'Stage 3a - Re-Submission', 8: 'Stage 4 - Awarded', 9: 'Stage 4 - Lost', 10: 'Stage 5 - Mobilising', 13: 'Stage 6a - Design Completed', 11: 'Stage 1a - Tender Mobilising', 12: 'Stage 1b - Tender Mobilised', 100: ''
                                    },
                                },
                            ]}
                            style={{ backgroundColor: getDarkMode() === 'dark' ? '#4f7e76' : '#AFD6CB' }}
                            data={this.state.data}
                            actions={this.state.actions

                                // rowData => ({
                                //     icon: 'check_circle',
                                //     tooltip: 'Activate',
                                //     onClick: (event, rowData) => this.handleDialog(true, rowData),
                                //     disabled: rowData.is_active
                                // }),
                                // rowData => ({
                                //     icon: 'cancel',
                                //     tooltip: 'Inactivate',
                                //     onClick: (event, rowData) => this.handleDialog(false, rowData),
                                //     disabled: !rowData.is_active
                                // })
                            }
                            options={{
                                padding: "dense",
                                pageSize: this.state.data.length,
                                paging: false,
                                pageSizeOptions: [10, 20, 50],

                                actionsColumnIndex: 6,
                                headerStyle: {
                                    backgroundColor: getDarkMode() === 'dark' ? '#4f7e76' : '#AFD6CB',

                                }
                            }}
                            editable={
                                ((access && access.access && access.access.l6a && access.access.l6a.l6a_m)) ? (
                                    {

                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    if (oldData) {
                                                        this.setState(prevState => {
                                                            const data = [...prevState.data];
                                                            data[data.indexOf(oldData)] = newData;
                                                            this.putOps(newData)
                                                            return { ...prevState, data };
                                                        });
                                                    }
                                                }, 600);
                                            }),
                                    }
                                ) : (null)}
                        />
                        <Dialog fullScreen open={this.state.open} onClose={handleClose} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        {(this.state.allData === 'null' && this.state.subproj.length === 1) ? (
                                            "Add Deliverables Schedule"
                                        ) : (this.state.allData === 'null' && this.state.subproj.length === 0) ? (
                                            "Add Tender"
                                        ) : (
                                            this.state.allData.name
                                        )}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            {(this.state.allData.audit_status === 10) ? (
                                <AllRateTabs value={this.state.allData} />
                            ) : (
                                <AddTender page={this.state.key} onprojedit={false} tender={this.props.value} value={this.state.allData} subproj={this.state.subproj} />

                            )}
                        </Dialog>
                        <Dialog onClose={handleClose1} aria-labelledby="simple-dialog-title" open={this.state.openEdit}>
                            <DialogTitle id="simple-dialog-title">Choose your edit</DialogTitle>
                            <List>
                                <ListItem autoFocus button onClick={() => this.setState({ key: 0, open: true, openEdit: false })}>

                                    <ListItemText primary="Project Edit" />

                                </ListItem>
                                <ListItem autoFocus button onClick={() => this.setState({ key: 1, open: true, openEdit: false })}>

                                    <ListItemText primary="Rates" />

                                </ListItem>

                                <ListItem autoFocus button onClick={() => this.setState({ key: 2, open: true, openEdit: false })}>

                                    <ListItemText primary="Deliverables" />

                                </ListItem>
                            </List>
                        </Dialog>
                        <Dialog maxWidth={'xs'} fullWidth={true} onClose={handleSelectUserClose} aria-labelledby="simple-dialog-title" open={this.state.openSelectUser}>
                            <DialogTitle id="simple-dialog-title">Change Order - Download as PDF</DialogTitle>
                            <DialogActions>
                                <Button onClick={handleSelectUserClose} color="primary">
                                    Cancel
                                </Button>
                                <Button disabled={this.state.buttonDisabled} color="primary" onClick={() => this.sendPDFMail('me')}>
                                    Download
                                </Button>
                            </DialogActions>
                            {/* {this.state.dialogLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                                <div>
                                    <DialogContent>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={this.state.activeUsers}
                                            value={this.state.selectedUser}
                                            style={{ minWidth: 500,}}
                                            onChange={this.onUserChange}
                                            getOptionLabel={(option) => option.last_name + ", " + option.first_name}
                                            renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        {(this.state.showAlert) ? (
                                            <Alert severity="warning"
                                                style={{ margin: '10px' }}
                                                action={
                                                    <Box display='flex' flexDirection={'column'} >
                                                        <Box marginBottom={'5px'}>
                                                            <Button variant='outlined' color="inherit" size="small" onClick={() => { this.setState({ selectedUser: null, showAlert: false }) }}>
                                                                cancel
                                                            </Button>
                                                        </Box>
                                                        <Box>
                                                            <Button color="inherit" size="small" variant='outlined' onClick={() => this.sendPDFMail('me')}>
                                                                send to me
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                }
                                            >
                                                Updated Rate Card is not saved. Please submit after filling remark for the change.
                                            </Alert>
                                        ) : (
                                            <>
                                                <Button onClick={handleSelectUserClose} color="primary">
                                                    Cancel
                                                </Button>
                                                <Button color="primary" onClick={() => this.sendPDFMail('selected_user')}>
                                                    Submit
                                                </Button>
                                            </>
                                        )}

                                    </DialogActions>
                                </div>
                            } */}
                        </Dialog>
                        <Dialog fullScreen open={this.state.openCalc} onClose={handleClose2} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleClose2} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        Summary for {this.state.allData.name}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <Summary value={this.state.allData} />
                        </Dialog>
                    </div>
                )}
                {(this.state.isLoading1) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (null)}
            </div>

        )
    }
}

export default withStyles(useStyles)(withSnackbar(TenderProjects));